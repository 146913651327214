import { Modal, RepresentativeModalForm, useShowToast } from "@madmedical/ui";
import { ApiFormWrapper } from "@madmedical/form";
import { useInviteMutation } from "../api";

interface Props {
    open: boolean;
    onClose: () => void;
}

const InviteModal = ({ open, onClose }: Props) => {
    const [invite] = useInviteMutation();
    const showToast = useShowToast();

    const handleSuccess = () => {
        showToast("inviteSent", { variant: "success" });
    };

    return (
        <Modal open={open} size="sm" onClose={onClose}>
            <ApiFormWrapper
                defaultValues={{ email: "", note: "" }}
                mutation={invite}
                onSuccess={handleSuccess}
                resetOnSuccess
            >
                {({ submitForm, isSuccess }) => (
                    <RepresentativeModalForm
                        mode="invite"
                        onClose={onClose}
                        onSubmitPress={submitForm}
                        isSuccess={isSuccess}
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default InviteModal;
