import { Box, HStack, Text, VStack } from "native-base";
import { invariant } from "@madmedical/utils";
import type { InsulinType } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import PressableLink from "../../atoms/PressableLink";
import {
    thumbnailRoot,
    thumbnailUnit,
    thumbnailValue,
    thumbnailValueSlot,
} from "../useStyles";
import { useInsulinAdministration } from "../../providers/insulinAdministration";

interface InsulinLevels {
    [InsulinType.Rapid]: number;
    [InsulinType.Basal]: number;
    [InsulinType.Other]: number;
}

const InsulinWidget = () => {
    const { administrations } = useInsulinAdministration();

    invariant(administrations, "Insulin administrations are not provided");

    const insulinLevels: InsulinLevels = administrations.reduce(
        (acc, item) => {
            const { product, units } = item;
            const { type } = product;
            acc[type] += units;

            return acc;
        },
        {
            rapid: 0,
            basal: 0,
            other: 0,
        }
    );

    const value = administrations.length;
    const { t } = useTranslation();
    const calculatePercentage = () => {
        const totalStings =
            insulinLevels.basal + insulinLevels.rapid + insulinLevels.other;
        const rapidPercentage = `${(insulinLevels.rapid / totalStings) * 100}%`;
        const basalPercentage = `${(insulinLevels.basal / totalStings) * 100}%`;
        const otherPercentage = `${(insulinLevels.other / totalStings) * 100}%`;

        return [rapidPercentage, basalPercentage, otherPercentage];
    };

    return (
        <VStack style={thumbnailRoot} minHeight={175}>
            <PressableLink route="insulin" params={{}} flex={1}>
                <Text fontSize="sm" fontWeight="medium" color="black">
                    {t("measures:insulinInsertions")}
                </Text>
                <HStack style={thumbnailValueSlot} space={1}>
                    {value > 0 ? (
                        <Text numberOfLines={1} style={thumbnailValue}>
                            {value}
                        </Text>
                    ) : (
                        <Text style={thumbnailUnit}>{t("none")} </Text>
                    )}
                    <Text style={thumbnailUnit}>{t("measures:insertion")}</Text>
                </HStack>

                {value > 0 ? (
                    <VStack flex={1} justifyContent="flex-end" mb={2} minH="10">
                        <Box display="flex" flexDirection="row">
                            <Box
                                bgColor={"blue.300"}
                                width={calculatePercentage()?.[0]}
                                height="12px"
                                position="relative"
                                borderRightWidth={
                                    insulinLevels.basal === 0 ? 2 : 0
                                }
                                borderRightColor={"white"}
                                borderLeftRadius="xs"
                            >
                                <Box position="absolute" top={-20} left={0}>
                                    <Text fontSize="xs" color="gray.600">
                                        {insulinLevels.rapid}E
                                    </Text>
                                </Box>
                                &nbsp;
                            </Box>
                            {insulinLevels.basal > 0 && (
                                <Box
                                    bgColor={"blue.500"}
                                    width={calculatePercentage()?.[1]}
                                    height="12px"
                                    position="relative"
                                    borderLeftWidth={2}
                                    borderLeftColor={"white"}
                                    borderRightWidth={2}
                                    borderRightColor={"white"}
                                >
                                    <Box
                                        position="absolute"
                                        top={-20}
                                        {...(insulinLevels.other > 0
                                            ? {
                                                  left: 0,
                                              }
                                            : {
                                                  right: 0,
                                              })}
                                    >
                                        <Text fontSize="xs" color="gray.600">
                                            {insulinLevels.basal}E
                                        </Text>
                                    </Box>
                                    &nbsp;
                                </Box>
                            )}
                            {insulinLevels.other > 0 && (
                                <Box
                                    bgColor={"gray.400"}
                                    width={calculatePercentage()?.[2]}
                                    height="12px"
                                    position="relative"
                                    borderLeftWidth={
                                        insulinLevels.basal === 0 ? 2 : 0
                                    }
                                    borderLeftColor={"white"}
                                    borderRightRadius="xs"
                                >
                                    <Box
                                        position="absolute"
                                        top={-20}
                                        right={0}
                                    >
                                        <Text
                                            fontSize="xs"
                                            color="gray.600"
                                            width={4}
                                            noOfLines={1}
                                            isTruncated={false}
                                        >
                                            {insulinLevels.other}E
                                        </Text>
                                    </Box>
                                    &nbsp;
                                </Box>
                            )}
                        </Box>
                    </VStack>
                ) : (
                    <Box flex={1} justifyContent="flex-end" mb={2} minH="10" />
                )}
            </PressableLink>
        </VStack>
    );
};

export default InsulinWidget;
