import { Box, HStack, Text, VStack } from "native-base";
import { useEffect, useState } from "react";
import { InsulinType } from "@madmedical/medical";
import { useFormContext } from "@madmedical/form";
import { useTranslation } from "@madmedical/i18n";
import SubmitButton from "../form/SubmitButton";
import TimeInput from "../form/TimeInput";
import Button from "../atoms/Button";
import ModalContent from "../molecules/ModalContent";
import FormSelect from "../form/FormSelect";
import FormTextAreaControl from "../form/FormTextAreaControl";
import { formatType } from "../util/insulinFormatters";
import MultiCheckboxSelect from "../form/MultiCheckboxSelect";
import useResponsive from "../util/useResponsive";
import { getDayListFull } from "../util/calendar";

interface Props {
    onClose: () => void;
    onSubmitPress: () => void;
    isSuccess: boolean;
    isEdit?: boolean;
}

const InsulinReminderModalForm = ({
    onClose,
    onSubmitPress,
    isSuccess,
    isEdit = false,
}: Props) => {
    const [isValid, setIsValid] = useState(false);
    const { isSmallScreen } = useResponsive();

    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose]);

    const insulinTypes = [
        { value: InsulinType.Rapid, label: formatType(InsulinType.Rapid) },
        { value: InsulinType.Basal, label: formatType(InsulinType.Basal) },
        { value: InsulinType.Other, label: formatType(InsulinType.Other) },
    ];

    const createWeekDays = () => {
        const days = getDayListFull();

        return days.map((day, index) => ({
            value: index === 6 ? 0 : index + 1, // Adjust index to match your desired value
            label: day,
        }));
    };
    const weekDays = createWeekDays();

    const { getValues, setValue, watch } = useFormContext<{
        daysOfWeek: number[];
        type: InsulinType;
        firstOccurrence: string;
        insulinType: InsulinType;
    }>();

    const chosenInsulinType = watch("type");
    const chosenDaysOfWeek = watch("daysOfWeek");

    useEffect(() => {
        if (chosenInsulinType && chosenDaysOfWeek.length > 0) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [chosenInsulinType, chosenDaysOfWeek]);

    const [firstTime, setFirstTime] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (isEdit) {
            const { insulinType, daysOfWeek, firstOccurrence } = getValues();
            setValue("type", insulinType);
            setValue("daysOfWeek", daysOfWeek);
            setFirstTime(firstOccurrence);
        }
    }, [isEdit, setValue, getValues]);
    const { t } = useTranslation();

    return (
        <ModalContent
            header={`${t("measures:injectionReminder")} ${
                isEdit ? t("editing") : t("setup")
            }`}
            footer={
                <>
                    <Button onPress={onClose} variant="outlined">
                        {t("cancel")}
                    </Button>
                    <SubmitButton
                        onPress={onSubmitPress}
                        label={t("save")}
                        isDisabled={!isValid}
                    />
                </>
            }
        >
            <VStack overflowY="clip">
                <HStack space={2} direction={isSmallScreen ? "column" : "row"}>
                    <TimeInput
                        name="firstOccurrence"
                        isRequired
                        label={t("appointment")}
                        previousTime={isEdit ? firstTime : undefined}
                    />
                    <MultiCheckboxSelect
                        list={weekDays.map((day) => ({
                            key: day.value.toString(),
                            text: day.label,
                        }))}
                        name="daysOfWeek"
                        label={t("repeat")}
                        width="100%"
                        allSelectedLabel={t("everyDay")}
                    />
                </HStack>
                <HStack mt={2}>
                    <FormSelect
                        choices={insulinTypes.map((type) => ({
                            value: type.value,
                            label: t(type.label),
                        }))}
                        name="type"
                        label={t("measures:insulinType")}
                        labelProps={{ _text: { color: "gray.800" } }}
                    />
                </HStack>
                <Box mt={2}>
                    <FormTextAreaControl
                        label={t("comment")}
                        name="comment"
                        labelProps={{
                            _text: { color: "gray.800" },
                        }}
                        maxLength={100}
                        textareaHeight={75}
                    />
                </Box>
                <Text mt={2} fontSize="sm" color="gray.500">
                    {t("max100Characters")}
                </Text>
            </VStack>
        </ModalContent>
    );
};

export default InsulinReminderModalForm;
