import { useState } from "react";
import {
    PregnancyDoctorEdit,
    PregnancyDoctorEditBreadcrumbs,
    useShowToast,
} from "@madmedical/ui";
import {
    DeleteDocumentDialog,
    UploadPregnancyDocModal,
} from "@madmedical/document";
import type { Ulid } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import type {
    InfantType,
    Pregnancy,
    PrenatalTestingMethod,
} from "@madmedical/medical";
import { useRouteNavigate } from "@madmedical/user";
import { useUpsertPregnancyMutation } from "../api";
import PregnancyDataProviderWrapper from "./PregnancyDataProviderWrapper";

interface EditedPregnancyData extends Pregnancy {
    patientId: Ulid;
}

enum ModalMode {
    Closed,
    Delete,
    Upload,
}

const DoctorEditPregnancy = () => {
    const [modalMode, setModalMode] = useState(ModalMode.Closed);
    const [idForModal, setIdForModal] = useState<Ulid>();
    const { t } = useTranslation();
    const navigate = useRouteNavigate();
    const showToast = useShowToast();

    const handleDeletePress = (id: Ulid) => {
        setIdForModal(id);
        setModalMode(ModalMode.Delete);
    };

    const handleClose = () => {
        setModalMode(ModalMode.Closed);
        setIdForModal(undefined);
    };

    const [editPregnancy] = useUpsertPregnancyMutation();

    const triggerEdit = async (
        editData: EditedPregnancyData
    ): Promise<void> => {
        const transformedEditData = {
            patientId: editData.patientId,
            expectedAt: editData?.expectedAt,
            twinMethod: editData?.twinPregnancyMethod as PrenatalTestingMethod,
            twinPregnancyComment: editData?.twinPregnancyComment ?? undefined,
            genderMethod: editData?.genderMethod as PrenatalTestingMethod,
            infantComment: editData.infantComment ?? undefined,
            isHighRisk: editData?.isHighRisk ? true : false,
            infants: editData?.infants as InfantType[],
        };
        try {
            const data = await editPregnancy(transformedEditData);
            if (data) {
                showToast(t("successfulSave"), { variant: "success" });
                navigate("patient", {
                    patientId: editData.patientId,
                });
            } else {
                showToast("saveUnsuccessful", { variant: "error" });
            }
        } catch (error) {
            showToast("saveUnsuccessful", { variant: "error" });
        }
    };

    return (
        <PregnancyDataProviderWrapper>
            <PregnancyDoctorEditBreadcrumbs />
            <PregnancyDoctorEdit
                uploadDocuments={() => setModalMode(ModalMode.Upload)}
                handleDeletePress={handleDeletePress}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                triggerEdit={triggerEdit}
            />
            <DeleteDocumentDialog
                id={idForModal}
                open={modalMode === ModalMode.Delete}
                onClose={handleClose}
            />
            <UploadPregnancyDocModal
                open={modalMode === ModalMode.Upload}
                onClose={handleClose}
            />
        </PregnancyDataProviderWrapper>
    );
};

export default DoctorEditPregnancy;
