import { useCallback, useEffect, useMemo, useState } from "react";
import { PregnancyWellBeingStatus } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { useChart } from "../providers/chart";
import { root } from "./useStyles";
import {
    $timeData,
    datesToTimeGridIndices,
    normalizeGraphProps,
} from "./utils";
import type {
    CommentGroupType,
    EvaluationBgHighlight,
    GraphData,
    GraphProps,
    MoodGroupType,
    TooltipType,
} from "./types";
import Evaluations from "./Evaluations";
import TimeStamps from "./TimeStamps";
import GraphSlot from "./GraphSlot";
import Crosshair from "./Crosshair";
import DragSelect from "./DragSelect";

export default function Graph({
    graphProps = {},
}: {
    graphProps?: GraphProps;
}) {
    const { selectedRange, onSelectRange } = useChart();

    const { i18n } = useTranslation();

    const graphData = useMemo<GraphData | undefined>(
        () => normalizeGraphProps(graphProps),
        [graphProps]
    );

    const { slot = { x: 0, y: 0 }, predictions } = graphData ?? {};

    const timeData = useMemo(
        () => (graphData ? $timeData({ graphData }) : null),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [graphData, i18n.language]
    );

    const initTimeGridIndices = timeData?.timeCoords.length
        ? datesToTimeGridIndices({
              dates: selectedRange ?? null,
              timeData,
          })
        : { from: null, to: null };

    const [commentState, setCommentState] = useState<CommentGroupType>({
        comments: [],
        groupAvatarUrl: "",
        groupMonogram: "",
        hasDoctor: false,
        id: "",
        onShow: false,
        x: 0,
    });

    const [tooltip, setTooltip] = useState<TooltipType>({
        bottom: 0,
        note: "",
        id: "",
        left: 0,
        msec: 0,
        onShow: false,
        isGrouped: false,
    });

    const [moodGroup, setMoodGroup] = useState<MoodGroupType>({
        status: [
            {
                id: "",
                createdAt: "",
                status: PregnancyWellBeingStatus.Moody,
                symptoms: [],
                comment: "",
            },
        ],
        id: "",
        onShow: false,
        x: 0,
    });

    const [evaluationBgHighlight, setEvaluationBgHighlight] =
        useState<EvaluationBgHighlight>({
            start: onSelectRange ? initTimeGridIndices?.from ?? null : null,
            end: onSelectRange ? initTimeGridIndices?.to ?? null : null,
            selected: -1,
        });

    // Hide tooltips on date-range change
    useEffect(() => {
        setTooltip((state) => ({ ...state, onShow: false }));
        setCommentState((state) => ({ ...state, onShow: false }));
        setMoodGroup((state) => ({ ...state, onShow: false }));
    }, [graphData?.coords]);

    useEffect(() => {
        if (!onSelectRange) return;

        const $start = evaluationBgHighlight.start;
        const $end = evaluationBgHighlight.end;

        if ($start === null) {
            onSelectRange(null);

            return;
        }

        const callbackIndexEnd = $end === $start ? $end + 1 : $end;
        const timeCoordIndexStart =
            callbackIndexEnd && $start < callbackIndexEnd
                ? $start
                : callbackIndexEnd;
        const timeCoordIndexEnd =
            callbackIndexEnd && callbackIndexEnd > $start
                ? callbackIndexEnd
                : $start;

        onSelectRange(
            timeData && timeCoordIndexStart !== null
                ? {
                      from: new Date(
                          timeData.timeCoords[timeCoordIndexStart].msec
                      ),
                      to: new Date(
                          timeData.timeCoords[timeCoordIndexEnd].msec - 1 // 23:59:59.9999
                      ),
                  }
                : null
        );
    }, [onSelectRange, evaluationBgHighlight, timeData]);

    // #####################

    const [offsetX, setOffsetX] = useState<number>(0);

    const anchor = useCallback((div: HTMLDivElement) => {
        if (!div) return;
        setOffsetX(div.getBoundingClientRect().x);
    }, []);

    return graphData && timeData ? (
        <div style={root} ref={anchor}>
            <TimeStamps timeData={timeData} slot={slot} />
            {predictions?.isPrediction ? (
                <></>
            ) : onSelectRange ? (
                <DragSelect
                    bgHighlight={evaluationBgHighlight}
                    setBgHighlight={setEvaluationBgHighlight}
                    graphData={graphData}
                    timeData={timeData}
                    offsetX={offsetX}
                />
            ) : (
                <Evaluations
                    graphData={graphData}
                    timeData={timeData}
                    evaluationBgHighlight={evaluationBgHighlight}
                    setEvaluationBgHighlight={setEvaluationBgHighlight}
                    setCommentState={setCommentState}
                    setTooltip={setTooltip}
                />
            )}
            <GraphSlot
                graphData={graphData}
                timeData={timeData}
                evaluationBgHighlight={evaluationBgHighlight}
                setEvaluationBgHighlight={setEvaluationBgHighlight}
                commentState={commentState}
                setCommentState={setCommentState}
                tooltip={tooltip}
                setTooltip={setTooltip}
                moodGroup={moodGroup}
                setMoodGroup={setMoodGroup}
            />
            {onSelectRange ? (
                <></>
            ) : (
                <Crosshair
                    graphData={graphData}
                    xMsecRatio={timeData.xMsecRatio}
                    offsetX={offsetX}
                />
            )}
        </div>
    ) : (
        <></>
    );
}
