import {
    ProviderConnectionStatus,
    connectionStatusForHumans,
    forHumans,
    logisticsForHumans,
} from "@madmedical/medical";
import { formatDateTime } from "@madmedical/utils";
import type { ReactNode } from "react";
import { Stack } from "native-base";
import { useTranslation } from "@madmedical/i18n";
import type { Device as DeviceModel } from "../providers/device";
import SimpleListItem from "../molecules/ListItem/SimpleListItem";
import Badge from "../atoms/Badge";
import Tag from "../atoms/Tag";
import useResponsive from "../util/useResponsive";

interface Props {
    device: DeviceModel;
}

interface ListItem {
    label: string;
    content: ReactNode;
}

const DeviceDetails = ({
    device: {
        status,
        metricTypes,
        connectedAt,
        lastRecordedAt,
        ownership,
        serialNumber,
    },
}: Props) => {
    const list: ListItem[] = [];

    const { isSmallScreen } = useResponsive();
    const { t } = useTranslation();
    if (serialNumber) {
        list.push({ label: t("serialNumber"), content: serialNumber });
    }
    if (ownership) {
        list.push({
            label: t("type"),
            content: logisticsForHumans(ownership),
        });
    }
    if (status !== ProviderConnectionStatus.Off) {
        list.push({
            label: t("connectionState"),
            content: (
                <Badge
                    mt={2}
                    size="sm"
                    variant={`ghost${
                        status === ProviderConnectionStatus.Active
                            ? "Success"
                            : "Error"
                    }`}
                >
                    {t(connectionStatusForHumans(status))}
                </Badge>
            ),
        });
    }
    list.push({
        label: t("measures:types"),
        // FYI: Use TagList here if shorter version needed
        content: isSmallScreen ? (
            <Stack flexDirection="row" flexWrap="wrap">
                {metricTypes.slice(0, 2).map((metric) => (
                    <Tag key={metric} mb={{ base: "0", md: "2" }} ml="2">
                        {t(`measures:${forHumans(metric)}`)}
                    </Tag>
                ))}
                {metricTypes.length > 2 && (
                    <Tag mb={{ base: "0", md: "2" }} ml="2">
                        +{metricTypes.length - 2}
                    </Tag>
                )}
            </Stack>
        ) : (
            <Stack flexDirection="row" flexWrap="wrap">
                {metricTypes.map((metric) => (
                    <Tag key={metric} mb={{ base: "0", md: "2" }} ml="2">
                        {t(`measures:${forHumans(metric)}`)}
                    </Tag>
                ))}
            </Stack>
        ),
    });
    if (connectedAt) {
        list.push({
            label: t("added"),
            content: formatDateTime(connectedAt),
        });
    }
    if (lastRecordedAt) {
        list.push({
            label: t("lastConnection"),
            content: formatDateTime(lastRecordedAt),
        });
    }

    return (
        <>
            {list.map(({ label, content }, index) => (
                <SimpleListItem
                    key={label}
                    label={label}
                    content={content}
                    borderBottomWidth={index === list.length - 1 ? 0 : 1}
                />
            ))}
        </>
    );
};

/* interface TagListProps {
    data: DeviceModel["metricTypes"];
}

const TagList = ({ data }: TagListProps) => {
    const { isSmallScreen } = useResponsive();

    const tags = useMemo(() => {
        const maxLength = isSmallScreen ? 2 : 10;
        let list: TagListProps["data"] = [];
        if (data.length >= maxLength) {
            list = data.slice(0, maxLength);
        }
        const tagList = list.map((item) => (
            <Tag key={item} mb={{ base: 0, md: 2 }} mr={2}>
                {forHumans(item)}
            </Tag>
        ));
        if (data.length >= maxLength) {
            tagList.push(
                <Tag key="counter-tag">+{data.length - maxLength}</Tag>
            );
        }

        return <>{tagList}</>;
    }, [isSmallScreen, data]);

    return tags;
}; */

export default DeviceDetails;
