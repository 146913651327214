import type { MetricType } from "@madmedical/medical";
import { usePatientId } from "@madmedical/user";
import { invariant } from "@madmedical/utils";
import {
    MeasurementProtocolModalForm,
    Modal,
    useShowToast,
} from "@madmedical/ui";
import { useTranslation } from "@madmedical/i18n";
import { ApiFormWrapper } from "@madmedical/form";
import {
    useGetAllProtocolsQuery,
    useGetThresholdsProtocolsQuery,
    useUpsertThresholdMutation,
} from "./api";

interface Props {
    metricType: MetricType;
    open: boolean;
    onClose: () => void;
}

const EditThresholdModal = ({ metricType, open, onClose }: Props) => {
    const { patientId } = usePatientId();
    const { t } = useTranslation();
    const { data: protocols, isLoading: isProtocolsLoading } =
        useGetAllProtocolsQuery({ metricType });
    const { thresholdsProtocol, isLoading: isThresholdsLoading } =
        useGetThresholdsProtocolsQuery(patientId, {
            selectFromResult: ({
                data: thresholdsProtocols,
                isLoading,
                isUninitialized,
            }) => ({
                thresholdsProtocol: thresholdsProtocols?.find(
                    (thresholdProtocol) =>
                        thresholdProtocol.metricType === metricType
                ),
                isLoading: isLoading || isUninitialized,
            }),
        });
    const [upsertThreshold] = useUpsertThresholdMutation();
    const showToast = useShowToast();

    const handleSuccess = () => {
        showToast(t("successfulSave"), { variant: "success" });
    };

    if (isProtocolsLoading || isThresholdsLoading) {
        return null;
    }

    invariant(protocols);

    if (!thresholdsProtocol) {
        // Nothing to edit
        return null;
    }

    const currentThreshold = thresholdsProtocol.thresholds[0];

    return (
        <Modal open={open} size="sm" onClose={onClose}>
            <ApiFormWrapper
                defaultValues={{
                    patientId,
                    threshold: currentThreshold,
                    instructions: thresholdsProtocol.protocol ?? undefined,
                }}
                mutation={upsertThreshold}
                onSuccess={handleSuccess}
            >
                {({ submitForm, isSuccess }) => (
                    <MeasurementProtocolModalForm
                        metricType={metricType}
                        protocols={protocols.map(
                            ({ instructions }) => instructions
                        )}
                        onClose={onClose}
                        onSubmitPress={submitForm}
                        isSuccess={isSuccess}
                    />
                )}
            </ApiFormWrapper>
        </Modal>
    );
};

export default EditThresholdModal;
