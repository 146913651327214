import { Text } from "react-native";
import { useTranslation } from "@madmedical/i18n";
import Tooltip from "./Tooltip";
import { colors, tooltipDragSelectText } from "./useStyles";
import type { TooltipType } from "./types";

export default function TooltipDragSelect({
    tooltip = {},
    transform,
}: {
    tooltip: TooltipType;
    transform?: [{ translateX: number }, { translateY: number }];
}) {
    const { t } = useTranslation();

    return (
        <Tooltip
            tooltip={tooltip}
            styleArrow={{ borderTopColor: colors.green600 }}
            styleContentBox={{ backgroundColor: colors.green600 }}
            transform={transform}
        >
            <Text style={tooltipDragSelectText}>{t("highlightInterval")}</Text>
        </Tooltip>
    );
}
