import _i18n from "i18next";
import { I18nextProvider as Provider } from "react-i18next";
import en from "./lng/en.json";
import hu from "./lng/hu.json";

export const i18n = _i18n.createInstance();

let language: string | undefined = undefined;

void i18n.init({
    ns: [
        "dashboard",
        "measures",
        "onboarding",
        "doctor",
        "preop",
        "pregnancy",
        "defaultMessages",
        "common",
    ],
    defaultNS: "common",
    resources: {
        en,
        hu,
    },
    debug: true,
    interpolation: {
        escapeValue: false,
    },
});
i18n.on("languageChanged", (lng) => {
    console.log("language changed", lng);
    language = lng;
});

export const getLanguage = () => {
    console.log("get language", language);
    if (!language) {
        console.log("fallback to english");
        language = "en";
    }

    return language;
};

export { Provider as I18nextProvider };
