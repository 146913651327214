import { downloadFile, previewFile } from "@madmedical/file";
import { useCallback, useState } from "react";
import { useTranslation } from "@madmedical/i18n";
import type { Document } from "../providers/document";
import useShowToast from "../atoms/useShowToast";

export default () => {
    const showToast = useShowToast();
    const [isDownloading, setDownloading] = useState(false);
    const { t } = useTranslation();
    const preview = useCallback(
        async ({ previewUrl, filename, mimeType }: Document) => {
            setDownloading(true);

            try {
                await previewFile(previewUrl, filename, mimeType);
            } catch {
                showToast(t("notSupportedFileType"), {
                    variant: "error",
                });
            } finally {
                setDownloading(false);
            }
        },
        [showToast, t]
    );

    const download = useCallback(
        async ({ downloadUrl, filename, mimeType }: Document) => {
            setDownloading(true);

            try {
                await downloadFile(downloadUrl, filename, mimeType);
            } catch {
                showToast(t("unSuccessfulDownload"), { variant: "error" });
            } finally {
                setDownloading(false);
                showToast(t("downloadFinished"), { variant: "success" });
            }
        },
        [showToast, t]
    );

    return { preview, download, isDownloading };
};
