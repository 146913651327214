import { HStack, SectionList } from "native-base";
import { useState } from "react";
import { useWindowDimensions } from "react-native";
import type { Ulid } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import EmptyState from "../../atoms/EmptyState";
import ListHeader from "../../molecules/ListHeader";
import InsulinReminderListItem from "../../molecules/ListItem/InsulinReminderListItem";
import type { InsulinReminder } from "../../providers/insulinReminder";
import type { MeasurementData } from "./InsulinReminderList";

type HeaderType = {
    title: string;
    badge: number;
};
type SectionType = {
    header: HeaderType;
    data: MeasurementData[];
};
interface Props {
    sections: SectionType[];
    onEndReached?: () => void;
    onDeletePress: (id: Ulid) => void;
    onEditPress?: (reminder: InsulinReminder) => void;
}

const InsulinReminderContentMobile = ({
    sections,
    onDeletePress,
    onEditPress,
}: Props) => {
    const [flatListPosition, setFlatListPosition] = useState(0);
    const screenHeight = useWindowDimensions().height;
    const { t } = useTranslation();

    return (
        <SectionList
            onLayout={(event) => {
                setFlatListPosition(event.nativeEvent.layout.y);
            }}
            maxHeight={Math.floor(screenHeight - flatListPosition - 230)}
            width="full"
            minWidth="full"
            showsVerticalScrollIndicator={false}
            sections={sections}
            renderSectionHeader={({ section: { header } }) => {
                const { title, badge } = header;

                if (badge === 0) {
                    return (
                        <>
                            <ListHeader title={title} badge={badge} />
                            <HStack
                                bgColor="white"
                                px={4}
                                py={5.5}
                                width="full"
                            >
                                <EmptyState
                                    title={t("measures:noData")}
                                    message={t(
                                        "measures:tryAgainOrSetupAnotherReminder"
                                    )}
                                    my={4}
                                    mx={5}
                                />
                            </HStack>
                        </>
                    );
                }

                return <ListHeader title={title} badge={badge} />;
            }}
            renderItem={({ item }) => (
                <>
                    <InsulinReminderListItem
                        {...item}
                        onDeletePress={onDeletePress}
                        onEditPress={onEditPress}
                    />
                </>
            )}
            keyExtractor={(item, index) => `section-list-item-${index}`}
            stickySectionHeadersEnabled
        />
    );
};

export default InsulinReminderContentMobile;
