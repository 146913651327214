import { Box, HStack, Text, VStack } from "native-base";
import { useEffect, useState } from "react";
import type { AdministrationSide, InsulinBodyPart } from "@madmedical/medical";
import { InsulinType } from "@madmedical/medical";
import { useFormContext } from "@madmedical/form";
import { invariant } from "@madmedical/utils";
import { useTranslation } from "@madmedical/i18n";
import SubmitButton from "../form/SubmitButton";
import DateTimeInput from "../form/DateTimeInput";
import Button from "../atoms/Button";
import ModalContent from "../molecules/ModalContent";
import FormSelect from "../form/FormSelect";
import FormControl from "../form/FormControl";
import type { InsulinBodySelectorOption } from "../util/insulinFormatters";
import {
    formatType,
    generateInsulinBodyLocations,
} from "../util/insulinFormatters";
import type { InsulinProduct } from "../providers/insulinAdministration";
import { useInsulinAdministration } from "../providers/insulinAdministration";
import useResponsive from "../util/useResponsive";
import FormTextAreaControl from "../form/FormTextAreaControl";

interface Props {
    onClose: () => void;
    onSubmitPress: () => void;
    isSuccess: boolean;
    isEdit?: boolean;
}

const InsulinAdministrationModalForm = ({
    onClose,
    onSubmitPress,
    isSuccess,
    isEdit = false,
}: Props) => {
    const { isSmallScreen } = useResponsive();

    const [isValid, setIsValid] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            onClose();
        }
    }, [isSuccess, onClose]);

    const { products } = useInsulinAdministration();
    const { t } = useTranslation();

    const insulinTypes = [
        { value: InsulinType.Rapid, label: formatType(InsulinType.Rapid) },
        { value: InsulinType.Basal, label: formatType(InsulinType.Basal) },
        { value: InsulinType.Other, label: formatType(InsulinType.Other) },
    ];

    const { setValue, getValues, unregister, watch } = useFormContext<{
        bodyLocationSelector: `${InsulinBodyPart}-${AdministrationSide}`;
        bodyLocation: {
            bodyPart: InsulinBodyPart;
            side: AdministrationSide;
        };
        product?: InsulinProduct;
        insulinType: InsulinType;
        productId: string;
        units: number;
    }>();

    const chosenInsulinType = watch("insulinType");
    const chosenInsulinProduct = watch("productId");
    const enteredInsulinUnit = watch("units");
    const chosenBodyLocation = watch("bodyLocationSelector");

    invariant(products, "No products found");

    const insulinProducts: {
        label: string;
        value: string;
    }[] = products
        .filter((item) => item.type === chosenInsulinType)
        .map((product) => ({
            value: product.id,
            label: product.name,
        }));

    const insulinDeliveryPlaces = generateInsulinBodyLocations();

    const updateLabelsWithTranslation = (data: InsulinBodySelectorOption) =>
        data.map((item) => {
            const [part1, part2] = item.label.split(" ");

            const cleanPart2 = part2.replace(/[()]/g, "");

            const translatedPart1 = t(part1);
            const translatedPart2 = t(cleanPart2);
            const newLabel = `${translatedPart1} (${translatedPart2})`;

            return {
                ...item,
                label: newLabel,
            };
        });
    const translatedPlaces = updateLabelsWithTranslation(insulinDeliveryPlaces);

    useEffect(() => {
        if (
            chosenInsulinType &&
            chosenInsulinProduct &&
            enteredInsulinUnit &&
            enteredInsulinUnit > 0 &&
            chosenBodyLocation
        ) {
            setIsValid(true);
        } else {
            setIsValid(false);
        }
    }, [
        chosenInsulinType,
        chosenInsulinProduct,
        enteredInsulinUnit,
        chosenBodyLocation,
    ]);

    useEffect(() => {
        if (isEdit) {
            const { bodyLocation, product } = getValues();
            setValue(
                "bodyLocationSelector",
                `${bodyLocation.bodyPart}-${bodyLocation.side}`
            );
            if (product) {
                setValue("insulinType", product?.type);
                setValue("productId", product?.id);
            }
        }
    }, [isEdit, setValue, getValues]);

    const onSubmit = () => {
        const { bodyLocationSelector } = getValues();
        const [bodyPart, side] = bodyLocationSelector.split("-") as [
            bodyPart: InsulinBodyPart,
            side: AdministrationSide
        ];
        setValue("bodyLocation", {
            bodyPart,
            side,
        });
        unregister("bodyLocationSelector", {
            keepIsValid: true,
        });

        onSubmitPress();
    };

    return (
        <ModalContent
            header={`${t("measures:insulinInjection")} ${
                isEdit ? t("editing") : t("recording")
            }`}
            footer={
                <>
                    <Button onPress={onClose} variant="outlined">
                        {t("cancel")}
                    </Button>
                    <SubmitButton
                        onPress={onSubmit}
                        label={t("save")}
                        isDisabled={!isValid}
                    />
                </>
            }
        >
            <VStack>
                <DateTimeInput
                    name="administeredAt"
                    isRequired
                    label={t("appointment")}
                />
                <HStack
                    space={2}
                    mt={2}
                    direction={isSmallScreen ? "column" : "row"}
                >
                    <FormSelect
                        choices={insulinTypes.map((type) => ({
                            value: type.value,
                            label: t(type.label),
                        }))}
                        name="insulinType"
                        label={t("measures:insulinType")}
                        labelProps={{ _text: { color: "gray.800" } }}
                    />
                    <FormSelect
                        choices={insulinProducts}
                        name="productId"
                        label={t("measures:insulinProduct")}
                        labelProps={{ _text: { color: "gray.800" } }}
                    />
                </HStack>
                <HStack
                    space={2}
                    mt={2}
                    direction={isSmallScreen ? "column" : "row"}
                >
                    <FormControl
                        label={t("units")}
                        name="units"
                        labelProps={{
                            _text: { color: "gray.800" },
                        }}
                        type="number"
                        height={35}
                        maxLength={2}
                        InputRightElement={
                            <Box px={2}>
                                <Text color="gray.500" fontSize="xs">
                                    {t("units")}
                                </Text>
                            </Box>
                        }
                    />
                    <FormSelect
                        choices={translatedPlaces}
                        name="bodyLocationSelector"
                        label={t("injectionLocation")}
                        labelProps={{ _text: { color: "gray.800" } }}
                    />
                </HStack>
                <Box mt={2}>
                    <FormTextAreaControl
                        label={t("comment")}
                        name="comment"
                        labelProps={{
                            _text: { color: "gray.800" },
                        }}
                        maxLength={100}
                        textareaHeight={75}
                    />
                </Box>
            </VStack>
        </ModalContent>
    );
};

export default InsulinAdministrationModalForm;
