import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "@madmedical/i18n";
import {
    PatientOnboarding,
    PatientOnboardingConfirmation,
    useShowToast,
} from "@madmedical/ui";
import type { GenderBaby, PregnancyData } from "@madmedical/medical";
import { UnderlyingCondition } from "@madmedical/medical";
import type { Ulid } from "@madmedical/utils";
import { invariant, toggleInArray, uniqueItems } from "@madmedical/utils";
import { ScrollView } from "react-native";
import {
    useBaseQuestionnaireMutation,
    useGetMeQuery,
    useGetUserPregnancyByPatientQuery,
} from "../api";
import usePatientId from "../usePatientId";
import useRouteNavigate from "../routing/useRouteNavigate";
import type { PregnantDetails } from "../pregnancy/pregnancyTypes";
import CalculateBirthTimeModalForm from "../pregnancy/CalculateBirthTimeModalForm";

interface MyPackages {
    type: string;
    maxDeviceCount: number;
    maxRepresentativeCount: number;
}

enum Step {
    Questionnaire,
    Consent,
}

type InfantType = {
    id?: Ulid | null;
    gender?: GenderBaby | null;
    doctorGender?: GenderBaby | null;
    givenName?: string | null;
    twinIndex?: number | null;
};

let isPregnancyLoaded = false;

const Onboarding = () => {
    const { patientId } = usePatientId();
    const { data: me, refetch: refetchMe } = useGetMeQuery();
    const [step, setStep] = useState(Step.Questionnaire);
    const [underlyings, setUnderlyings] = useState<UnderlyingCondition[]>(
        me?.patient?.underlyingConditions ?? []
    );
    const [bodyHeight, setBodyHeight] = useState<number | undefined>(
        me?.height?.metric?.cm
    );
    const [submit, { isLoading: isSaving }] = useBaseQuestionnaireMutation();
    const showToast = useShowToast();
    const navigate = useRouteNavigate();
    const { t } = useTranslation();

    const handleUnderlyingChange = (
        underlying: UnderlyingCondition,
        selected: boolean
    ) => {
        setUnderlyings((prev) => {
            if (prev.includes(underlying) === selected) {
                return prev;
            }

            return toggleInArray(prev, underlying);
        });
    };
    const twinDetails: PregnantDetails[] = useMemo(
        () => [
            {
                name: "gender-1",
                label: t("pregnancy:babyGender"),
                value: "surprise",
                isBoolean: false,
                isVisible: true,
                twinIndex: 1,
            },
            {
                name: "nameChoose-1",
                label: t("pregnancy:haveYouChooseName"),
                value: false,
                isBoolean: true,
                isVisible: true,
                twinIndex: 1,
            },
            {
                name: "nameInput-1",
                label: t("pregnancy:babyName"),
                value: "",
                isBoolean: false,
                isVisible: false,
                twinIndex: 1,
            },
        ],

        [t]
    );

    const defaultPregnantDetails: PregnantDetails[] = [
        {
            name: "expectedAt",
            label: t("pregnancy:expectedDateOfBirth"),
            value: new Date().toString(),
            isBoolean: false,
            isVisible: true,
        },
        {
            name: "twins",
            label: t("pregnancy:expectingTwins"),
            value: false,
            isBoolean: true,
            isVisible: false, // Twins story not needded for nowó
        },
        {
            name: "twinsCount",
            label: t("pregnancy:howManyFetus"),
            value: 1,
            isBoolean: false,
            isVisible: false,
        },
    ];

    const [pregnantDetails, setPregnantDetails] = useState<PregnantDetails[]>([
        ...defaultPregnantDetails,
        ...twinDetails,
    ]);

    const [isPregnant, setIsPregnant] = useState<boolean>(
        underlyings.includes(UnderlyingCondition.Pregnancy) ? true : false
    );

    const handlePregnantChange = (selected: boolean) => {
        setIsPregnant(selected);
        handleUnderlyingChange(UnderlyingCondition.Pregnancy, selected);
    };

    const { data: pregnancyData } = useGetUserPregnancyByPatientQuery(
        {
            patientId,
        },
        {
            skip: !underlyings.includes(UnderlyingCondition.Pregnancy),
        }
    );

    useEffect(() => {
        if (!pregnancyData || !isPregnancyLoaded) {
            return;
        }
        const { expectedAt, infants } = pregnancyData;

        const getValue = (name: string, infant: InfantType) => {
            if (!infant) {
                return null;
            }
            if (name.startsWith("gender")) {
                return infant.gender ?? "surprise";
            }
            if (name.startsWith("nameInput")) {
                return infant.givenName ?? "";
            }
            if (name.startsWith("nameChoose")) {
                return !!infant.givenName;
            }
        };

        const transformedTwins = infants.flatMap(
            (infant: InfantType, index: number) =>
                twinDetails.map((detail: PregnantDetails) => ({
                    ...detail,
                    id: infant.id,
                    twinIndex: index + 1,
                    name: `${detail.name.split("-")[0]}-${index + 1}`,
                    value: getValue(detail.name, infant),
                    isVisible: true, // get some logic for visiblity ???
                }))
        ) as PregnantDetails[];

        setPregnantDetails(() => [
            {
                name: "expectedAt",
                label: t("pregnancy:expectedDateOfBirth"),
                value: expectedAt,
                isBoolean: false,
                isVisible: true,
            },
            {
                name: "twins",
                label: t("pregnancy:expectingTwins"),
                value: infants.length > 1,
                isBoolean: true,
                isVisible: infants.length > 1,
            },
            {
                name: "twinsCount",
                label: t("pregnancy:howManyFetus"),
                value: infants.length,
                isBoolean: false,
                isVisible: infants.length > 1,
            },
            ...transformedTwins,
        ]);
        setTimeout(() => {
            isPregnancyLoaded = true;
        }, 1000);
    }, [pregnancyData, t, twinDetails]);

    const handlePregnantDetailsChange = useCallback(
        (
            name: string,
            value?: boolean | string | null,
            isVisible?: boolean | null
        ) => {
            setPregnantDetails((details) =>
                details.map((detail) =>
                    detail.name === name
                        ? {
                              ...detail,
                              value: value ?? detail.value,
                              isVisible: isVisible ?? detail.isVisible,
                          }
                        : detail
                )
            );
        },
        []
    );

    useEffect(() => {
        if (!pregnantDetails || pregnantDetails.length === 0 || !isPregnant) {
            return;
        }
        const isTwinsSelected =
            pregnantDetails?.find((detail) => detail.name === "twins")?.value ??
            false;
        let isTwinCount = pregnantDetails?.find(
            (detail) => detail.name === "twinsCount"
        ) ?? {
            name: "twinsCount",
            value: 1,
            isBoolean: false,
            isVisible: false,
        };
        if (isTwinCount?.value === 1 && isTwinsSelected) {
            isTwinCount = { ...isTwinCount, value: 2 };
            setPregnantDetails(
                (details) =>
                    details.map((detail) =>
                        detail.name === "twinsCount" ? isTwinCount : detail
                    ) as PregnantDetails[]
            );
        }
        if (isTwinsSelected !== isTwinCount?.isVisible) {
            if (!isTwinsSelected) {
                const filteredItems = pregnantDetails.filter((detail) => {
                    if (detail.twinIndex && detail.twinIndex > 1) {
                        return false;
                    }

                    return true;
                });
                setPregnantDetails(filteredItems);
            }
            handlePregnantDetailsChange(
                "twinsCount",
                null,
                isTwinsSelected as boolean
            );
        }

        if (
            isTwinsSelected &&
            isTwinCount &&
            (pregnantDetails.every(
                (item) => item.twinIndex != +isTwinCount.value
            ) ||
                (pregnantDetails?.[pregnantDetails.length - 1]
                    ?.twinIndex as number) > +isTwinCount.value)
        ) {
            const filteredItems = pregnantDetails.filter((detail) => {
                if (detail.twinIndex && detail.twinIndex > +isTwinCount.value) {
                    return false;
                }

                return true;
            });

            const newItems = Array(+isTwinCount?.value - 1)
                .fill("")
                .map((_, index) => {
                    const newItem = [...twinDetails].map((detail) => {
                        const newDetail = { ...detail };
                        newDetail.name = `${detail.name.split("-")[0]}-${
                            index + 2
                        }`;
                        newDetail.twinIndex = index + 2;

                        return newDetail;
                    });

                    return newItem;
                })
                .flat();
            setPregnantDetails(
                uniqueItems([...filteredItems, ...newItems], "name")
            );
        }
        pregnantDetails.forEach((detail) => {
            if (detail.name.includes("nameChoose")) {
                const detailIndex = detail.name.split("-")[1];
                const isNameSelected = detail.value;
                const isNameInput = pregnantDetails?.find(
                    (input) => input.name === `nameInput-${detailIndex}`
                );
                if (isNameSelected !== isNameInput?.isVisible) {
                    handlePregnantDetailsChange(
                        `nameInput-${detailIndex}`,
                        null,
                        isNameSelected as boolean
                    );
                }
            }
        });
        isPregnancyLoaded = true;
    }, [pregnantDetails, handlePregnantDetailsChange, isPregnant, twinDetails]);

    const [calculateBirthTime, setCalculateBirthTime] =
        useState<boolean>(false);

    const calculateBirthTimeValues = (
        lastMenstruationDate: Date,
        menstruationCycleLength: number
    ) => {
        const totalDays = 280;

        const expectedDate = new Date(lastMenstruationDate);
        if (menstruationCycleLength === 28) {
            expectedDate.setDate(expectedDate.getDate() + totalDays);
        }
        if (menstruationCycleLength > 28) {
            const difference = menstruationCycleLength - 28;
            expectedDate.setDate(
                expectedDate.getDate() + difference + totalDays
            );
        }
        if (menstruationCycleLength < 28) {
            const difference = 28 - menstruationCycleLength;
            expectedDate.setDate(
                expectedDate.getDate() - difference + totalDays
            );
        }

        setPregnantDetails((details) =>
            details.map((detail) =>
                detail.name === "expectedAt"
                    ? {
                          ...detail,
                          value: expectedDate.toString(),
                      }
                    : detail
            )
        );
        setCalculateBirthTime(false);
    };

    const handleSubmit = () => {
        invariant(bodyHeight);

        const infants: InfantType[] = [
            {
                twinIndex: 1,
            },
        ];

        for (const item of pregnantDetails) {
            const itemName = item.name;
            const twinIndex = item.twinIndex;
            const existingInfant = infants.find(
                (infant) => infant.twinIndex == twinIndex
            );

            if (!existingInfant) {
                const newInfant: InfantType = { twinIndex: twinIndex };
                if (itemName.startsWith("gender-")) {
                    const genderValue = item.value as GenderBaby;
                    if (genderValue !== "surprise") {
                        newInfant.gender = genderValue;
                    }
                } else if (
                    itemName.startsWith("nameChoose-") &&
                    item.value === true
                ) {
                    const correspondingNameInput = pregnantDetails.find(
                        (inputItem) =>
                            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                            inputItem?.name === `nameInput-${twinIndex}`
                    );

                    if (correspondingNameInput) {
                        newInfant.givenName =
                            correspondingNameInput.value as string;
                    }
                }
                infants.push(newInfant);
            }

            if (itemName.startsWith("gender-") && existingInfant) {
                const genderValue = item.value as GenderBaby;
                if (genderValue !== "surprise") {
                    existingInfant.gender = genderValue;
                }
            } else if (
                itemName.startsWith("nameChoose-") &&
                item.value === true &&
                existingInfant
            ) {
                const correspondingNameInput = pregnantDetails.find(
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    (inputItem) => inputItem?.name === `nameInput-${twinIndex}`
                );

                if (correspondingNameInput) {
                    existingInfant.givenName =
                        correspondingNameInput.value as string;
                }
            }
        }

        const expectedAtValue = pregnantDetails.find(
            (item) => item.name === "expectedAt"
        )?.value;
        const expectedAtDate = new Date(expectedAtValue as string);

        const pregnancyData = {
            expectedAt: expectedAtValue
                ? expectedAtDate.toISOString()
                : undefined,
            infants: infants.filter((item) => item.twinIndex !== undefined),
            isPregnant,
        };

        void (async () => {
            await submit({
                patientId,
                underlyingConditions: underlyings,
                bodyHeightCm: bodyHeight,
                pregnancyData: isPregnant
                    ? (pregnancyData as PregnancyData)
                    : undefined,
            }).unwrap();

            showToast(t("successfulSave"), { variant: "success" });

            await refetchMe();

            navigate("dashboard", {}, { replace: true });
        })();
    };

    // This should happen on backend but this place is not using forms… TODO: Rethink at some point
    const isBodyHeightValid = bodyHeight && bodyHeight > 30 && bodyHeight < 250;

    const packages = (me?.patient?.packages ?? []) as MyPackages[];
    const isPregnancyPackage = !!(
        me?.patient && packages.some((item) => item?.type === "pregnancy")
    );
    const isInsulinPackage = !!(
        me?.patient && packages.some((item) => item?.type === "diabetes")
    );

    switch (step) {
        case Step.Questionnaire:
            return (
                <>
                    <ScrollView>
                        <PatientOnboarding
                            bodyHeight={bodyHeight}
                            selectedUnderlyings={underlyings}
                            onUnderlyingChange={handleUnderlyingChange}
                            onBodyHeightChange={setBodyHeight}
                            onNextPress={handleSubmit}
                            isNextDisabled={!isBodyHeightValid}
                            pregnantDetails={pregnantDetails}
                            onPregnantDetailsChange={
                                handlePregnantDetailsChange
                            }
                            openCalculateBirthTimeModal={() =>
                                setCalculateBirthTime(true)
                            }
                            isPregnant={isPregnant}
                            setIsPregnant={handlePregnantChange}
                            patient={me?.patient}
                            packages={{
                                pregnancy: isPregnancyPackage,
                                diabetes: isInsulinPackage,
                            }}
                        />
                    </ScrollView>
                    <CalculateBirthTimeModalForm
                        open={calculateBirthTime}
                        onClose={() => setCalculateBirthTime(false)}
                        transmittedValues={calculateBirthTimeValues}
                    />
                </>
            );
        case Step.Consent:
            return (
                <ScrollView>
                    <PatientOnboardingConfirmation
                        onPreviousPress={() => {
                            setStep(Step.Questionnaire);
                        }}
                        onNextPress={handleSubmit}
                        isSaving={isSaving}
                    />
                </ScrollView>
            );
    }
};

export default Onboarding;
