import { useAuth } from "@madmedical/store";
import { useGetMeQuery } from "./api";
import type { Me } from "./model";

// Jumping through hoops to avoid null-checks everywhere, here we cheat and expect AuthWrapper to redirect before anything
const emptyMe: Me = {
    roles: {
        isPatient: false,
        isDoctor: false,
        isRepresentative: false,
        isCallCenter: false,
        isHotline: false,
        isAdmin: false,
        isSuperAdmin: false,
    },
    patient: null,
    doctor: null,
    representative: null,
    hotline: null,
};

export default () => {
    const { isTokenAvailable } = useAuth();
    const { data } = useGetMeQuery(undefined, {
        skip: !isTokenAvailable,
    });

    if (!data) {
        return emptyMe;
    }

    return data;
};
