import { Route, Routes } from "@madmedical/routing";
import {
    DoctorPatients,
    Onboarding,
    useMe,
    useRoutePaths,
} from "@madmedical/user";
import { DoctorPatient, PatientDashboard } from "@madmedical/dashboard";
import {
    AllDevices,
    ConnectedDevices,
    Device,
    OAuthResult,
} from "@madmedical/device";
import {
    Device as CallCenterDevice,
    Devices as CallCenterDevices,
    Patient as CallCenterPatient,
    Patients as CallCenterPatients,
} from "@madmedical/callcenter";
import {
    AggregatedMeasurements,
    DoctorEditPregnancy,
    InsulinAdministrationPage,
    InsulinPage,
    InsulinReminderPage,
    MeasurementList,
    Measurements,
    Opinion,
    OpinionForm,
    Opinions,
    Prediction,
    Predictions,
    PregnancyBabyPage,
    PregnancyBodyChangePage,
    PregnancyFeelingPage,
    PregnancyMeasurementPage,
} from "@madmedical/measurement";
import { Representatives } from "@madmedical/representative";
import { Document, Documents } from "@madmedical/document";
import { Login, LogoutButton } from "@madmedical/auth";
import { PreOpForm, PreOpResults } from "@madmedical/preop";
import PageNotFound from "./PageNotFound";
import Main from "./Main";

const Routing = () => {
    const {
        roles: {
            isDoctor,
            isPatient,
            isRepresentative,
            isHotline,
            isAdmin,
            isCallCenter,
            isSuperAdmin,
        },
    } = useMe();
    const { getPath } = useRoutePaths();

    return (
        <Routes>
            <Route path="" element={<Main />}>
                {(isPatient ||
                    isRepresentative ||
                    isDoctor ||
                    isHotline ||
                    isAdmin ||
                    isSuperAdmin) && (
                    <>
                        <Route
                            path={getPath("measurements")}
                            element={<Measurements />}
                        />
                        <Route
                            path={getPath("measurements_list")}
                            element={<MeasurementList />}
                        />
                        <Route
                            path={getPath("insulin")}
                            element={<InsulinPage />}
                        />
                        <Route
                            path={getPath("insulin_administrations")}
                            element={<InsulinAdministrationPage />}
                        />
                        <Route
                            path={getPath("insulin_reminders")}
                            element={<InsulinReminderPage />}
                        />
                        <Route
                            path={getPath("opinions")}
                            element={<Opinions />}
                        />
                        <Route
                            path={getPath("opinion")}
                            element={<Opinion />}
                        />
                        <Route
                            path={getPath("opinion_edit")}
                            element={<OpinionForm />}
                        />
                        <Route
                            path={getPath("opinion_create")}
                            element={<OpinionForm />}
                        />
                        <Route
                            path={getPath("documents_general")}
                            element={<Documents withOpinion={false} />}
                        />
                        <Route
                            path={getPath("documents_opinion")}
                            element={<Documents withOpinion={true} />}
                        />
                        <Route
                            path={getPath("document")}
                            element={<Document />}
                        />
                        <Route
                            path={getPath("pregnancy_body")}
                            element={<PregnancyBodyChangePage />}
                        />
                        <Route
                            path={getPath("pregnancy_baby")}
                            element={<PregnancyBabyPage />}
                        />
                        <Route
                            path={getPath("pregnancy_measurement")}
                            element={<PregnancyMeasurementPage />}
                        />
                        <Route
                            path={getPath("pregnancy_feeling")}
                            element={<PregnancyFeelingPage />}
                        />
                    </>
                )}
                {(isPatient || isRepresentative) && (
                    <>
                        <Route
                            path={getPath("dashboard")}
                            element={<PatientDashboard />}
                        />
                        <Route
                            path={getPath("representatives")}
                            element={<Representatives />}
                        />
                        <Route
                            path={getPath("onboarding")}
                            element={<Onboarding />}
                        />
                        <Route
                            path={getPath("preop")}
                            element={<PreOpForm />}
                        />
                    </>
                )}
                {isPatient && (
                    <>
                        <Route
                            path={getPath("devices_connected")}
                            element={<ConnectedDevices />}
                        />
                        <Route
                            path={getPath("devices_all")}
                            element={<AllDevices />}
                        />
                        <Route path={getPath("device")} element={<Device />} />
                    </>
                )}
                {(isDoctor || isHotline || isAdmin || isSuperAdmin) && (
                    <>
                        <Route
                            path={getPath("dashboard")}
                            element={<DoctorPatients />}
                        />
                        <Route
                            path={getPath("patients")}
                            element={<DoctorPatients />}
                        />
                        <Route
                            path={getPath("patients_measurements")}
                            element={<AggregatedMeasurements />}
                        />
                        <Route
                            path={getPath("patient")}
                            element={<DoctorPatient />}
                        />
                        <Route
                            path={getPath("predictions")}
                            element={<Predictions />}
                        />
                        <Route
                            path={getPath("prediction")}
                            element={<Prediction />}
                        />
                        <Route
                            path={getPath("preop")}
                            element={<PreOpResults />}
                        />
                        <Route
                            path={getPath("doctor_edit_pregnancy")}
                            element={<DoctorEditPregnancy />}
                        />
                    </>
                )}
                {isCallCenter && (
                    <>
                        <Route
                            path={getPath("dashboard")}
                            element={<CallCenterDevices />}
                        />
                        <Route
                            path={getPath("callcenter_devices")}
                            element={<CallCenterDevices />}
                        />
                        <Route
                            path={getPath("callcenter_device")}
                            element={<CallCenterDevice />}
                        />
                        <Route
                            path={getPath("patients")}
                            element={<CallCenterPatients />}
                        />
                        <Route
                            path={getPath("patient")}
                            element={<CallCenterPatient />}
                        />
                    </>
                )}
                <Route path="oauth-result" element={<OAuthResult />} />
                <Route path="login" element={<Login />} />
                <Route path="logout" element={<LogoutButton />} />
                <Route path="*" element={<PageNotFound />} />
            </Route>
        </Routes>
    );
};

export default Routing;
