import { Box, Heading, Stack } from "native-base";
import type { InsulinType } from "@madmedical/medical";
import { useTranslation } from "@madmedical/i18n";
import { createDateRangeArray, groupByDay } from "@madmedical/utils";
import { useMemo } from "react";
import { InsulinPortioningGraph } from "../../../charts";
import Paper from "../../../atoms/Paper";
import useResponsive from "../../../util/useResponsive";
import DateRangePicker from "../../../organisms/DateRangePicker";
import { useInsulinAdministration } from "../../../providers/insulinAdministration";
import { useChart } from "../../../providers/chart";
import ListHeader from "../../../molecules/ListHeader";

type PointData = {
    y: number;
    value: number;
    status: "OK" | "DIFFERENCE" | "ALERT";
    insertions: {
        [InsulinType.Rapid]?: number;
        [InsulinType.Basal]?: number;
        [InsulinType.Other]?: number;
    };
    administeredAt: Date;
};

const InsulinPortioningChart = () => {
    const { isSmallScreen } = useResponsive();
    const { dateRange } = useChart();
    const { bloodSugareAverages, administrations } = useInsulinAdministration();
    const { t } = useTranslation();
    const graphData = useMemo(() => {
        const dateArray = createDateRangeArray(dateRange);
        const groupedAdministrations = groupByDay(
            administrations,
            "administeredAt"
        );
        const maxValue = Math.max(
            ...Object.values(bloodSugareAverages).map((item) => Math.ceil(item))
        );

        return dateArray.map<PointData>((date) => {
            const dateHours = date.setHours(0, 0, 0, 0);

            const insertions = groupedAdministrations[dateHours]?.reduce(
                (
                    acc: { rapid: number; basal: number; other: number },
                    curr
                ) => {
                    acc[curr.product.type] += curr.units;

                    return acc;
                },
                {
                    rapid: 0,
                    basal: 0,
                    other: 0,
                }
            ) ?? { rapid: 0, basal: 0, other: 0 };

            const fullArea = 240;
            const svgViewport = 170;
            const findValue =
                bloodSugareAverages[dateHours] > 0
                    ? (bloodSugareAverages[dateHours] * fullArea) / maxValue
                    : 0;

            const y = svgViewport + 1 - findValue;

            return {
                y,
                value: bloodSugareAverages[dateHours],
                status: "OK",
                insertions,
                administeredAt: new Date(dateHours),
            };
        });
    }, [dateRange, bloodSugareAverages, administrations]);

    return (
        <Box width="full">
            {isSmallScreen && (
                <ListHeader
                    title={t("measures:insulinInsertions2")}
                    size="xs"
                    borderBottomWidth={0}
                    bgColor="none"
                    px={4}
                />
            )}
            <Paper borderTopLeftRadius={0} borderTopRightRadius={0}>
                <Stack
                    alignItems={isSmallScreen ? "flex-start" : "center"}
                    direction={isSmallScreen ? "column" : "row"}
                    justifyContent={isSmallScreen ? "flex-start" : "between"}
                    space={6}
                    w="full"
                    borderBottomWidth={isSmallScreen ? 0 : 1}
                    borderBottomColor={"gray.100"}
                    mb={4}
                    py={2}
                >
                    {!isSmallScreen && (
                        <Box flex="1" p={6}>
                            <Heading
                                size="xs"
                                fontWeight="medium"
                                numberOfLines={1}
                                textTransform="uppercase"
                                color={"black"}
                            >
                                {t("measures:insulinInsertions2")}
                            </Heading>
                        </Box>
                    )}

                    <Box p={4}>
                        <DateRangePicker
                            periods={["week", "month"]}
                            key="portion"
                        />
                    </Box>
                </Stack>

                {graphData.length !== 0 && (
                    <InsulinPortioningGraph pointData={graphData} />
                )}
            </Paper>
        </Box>
    );
};

export default InsulinPortioningChart;
